//import store from '@/state/store'

export default [
    {
        path: "/",
        name: "home",
        meta: {
            authRequired: true
        },
        component: () => import("../views/pages/product/index")
    },
    {
        path: "/login",
        name: "login",
        meta: {
            authRequired: false
        },
        component: () => import("../views/pages/account/login_new")
    },
    {
        path: "/signup_biz",
        name: "signup_biz",
        meta: {
            authRequired: false
        },
        component: () => import("../views/pages/account/signup/signup_biz.vue")
    },
    {
        path: "/signup",
        name: "signup",
        meta: {
            authRequired: false
        },
        component: () => import("../views/pages/account/signup/signup.vue")
    },
    {
        path: "/auth/myinfo",
        name: "myinfo",
        meta: {
            authRequired: true
        },
        component: () => import("../views/pages/account/myInfo/myinfo.vue")
    },
    /*{
          path: '/product-board',
          name: 'product-board',
          meta: {
              authRequired: true,
          },
          component: () => import('../views/pages/product/index')
      },*/
    {
        path: "/flyers",
        name: "flyers",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/flyers/index")
    },
    {
        path: "/popVerticalFlyers",
        name: "verticalFlyers",
        meta: {
            authRequired: true
        },
        component: () => import("../views/pages/popVerticalFlyers/VerticalFlyers")
    },
    {
        path: "/popVerticalFlyers/verticalImageManager",
        name: "verticalImageManager",
        meta: {
            authRequired: true
        },
        component: () =>
            import("../views/pages/popVerticalFlyers/VerticalImageManager"),
        props: true
    },
    {
        path: "/flyer/ImageManager",
        name: "ImageManager",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/flyers/ImageManager"),
        props: true
    },
    {
        path: "/issue_coupon",
        name: "issuecoupon",
        meta: {
            authRequired: true
        },
        component: () => import("../views/pages/coupon/coupon")
    },
    {
        path: "/coupon_list",
        name: "couponlist",
        meta: {
            authRequired: true
        },
        component: () => import("../views/pages/coupon/coupon_list")
    },
    {
        path: "/voc",
        name: "voiceOfCustomers",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/voc/voc")
    },
    {
        path: "/macltalk",
        name: "macltalk",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/maclTalk/sendTalk")
    },
    {
        path: "/push/msg",
        name: "pushMsg",
        meta: {
            authRequired: true
        },
        component: () => import("../views/pages/push/pushmsg/pushmsg")
    },
    {
        path: "/push/history",
        name: "pushHistory",
        meta: {
            authRequired: true
        },
        component: () => import("../views/pages/push/pushHistory/pushHistory.vue")
    },
    {
        path: "/push/member",
        name: "pushMember",
        meta: {
            authRequired: true
        },
        component: () => import("../views/pages/push/pushNewMember/pushNewMember.vue")
    },
    {
        path: "/eventlist",
        name: "eventlist",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/event/eventlist")
    },
    {
        path: "/eventoriginlist",
        name: "eventOriginList",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/eventOrigin/eventlistOrigin.vue")
    },
    {
        path: "/404",
        name: "notFound",
        /*meta: {
              authRequired: false
            },*/
        component: () => import("../views/pages/errors/404-basic")
    },
    {
        path: "*",
        redirect: "/404"
    },
    {
        path: "/talk/mngnewmembers",
        name: "mngNewMembers",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/maclTalk/mngNewMembers")
    },
    {
        path: "/talk/kakaonewmembers",
        name: "kakaoNewMembers",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/kakaoTalk/kakaoNewMembers")
    },
    {
        path: "/talk/sendtalk",
        name: "sendKakao",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/kakaoTalk/sendKakaoTalk")
    },
    {
        path: "/talk/mngTemplete",
        name: "templateManagement",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/kakaoTalk/templateManagement")
    },
    {
        path: "/talk/history",
        name: "talkHistory",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/kakaoTalk/talkHistory")
    },
    {
        path: "/talk/mngdeposit",
        name: "pointManagement",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/kakaoTalk/pointManagement")
    },
    {
        path: "/talk/history/macltalk",
        name: "maclTalkHistory",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/maclTalk/maclTalkHistory")
    },
    {
        path: "/talk/customer/groups",
        name: "groupManagement",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/maclTalk/groupManagement")
    },
    {
        path: "/bestproduct",
        name: "bestproduct",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/bestProduct/bestList.vue")
    },
    {
        path: "/talk/viewshistory",
        name: "viewshistory",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/maclTalk/viewsHistory")
    },
    {
        path: "/barcode",
        name: "custombarcode",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/customBarcode/index")
    },
    {
        path: "/guide",
        name: "guide",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/guide/guideMain")
    },
    {
        path: "/testhtml",
        name: "testHtml",
        meta: {
            authRequired: true
        },
        component: () => import("../views/(duplicate)/testHtml.vue")
    },
    {
        path: "/board/qa",
        name: "q&a",
        meta: {
            authRequired: true
        },
        component: () => import("@/views/pages/Q&A/Q&AIndex/documentIndex.vue")
    },
    {
        path: "/board/qa/detail",
        name: "q&aDetail",
        meta: {
            authRequired: true
        },
        component: () => import("@/views/pages/Q&A/Q&ADetail/detail.vue")
    },
    {
        path: "/board/qa/write",
        name: "q&aWrite",
        meta: {
            authRequired: true
        },
        component: () => import("@/views/pages/Q&A/Q&AWrite/addQuestion.vue")
    },
    {
        path: "/product/category",
        name: "productCategory",
        meta: {
            authRequired: true
        },
        component: () => import("@/views/pages/categories/CategoryManagement.vue")
    },
    {
        path: "/show/privacy",
        name: "showPrivacy",
        meta: {
            authRequired: false
        },
        component: () => import("@/views/pages/showHtml/showPrivacy.vue")
    },
    {
        path: "/show/terms",
        name: "showTerms",
        meta: {
            authRequired: false
        },
        component: () => import("@/views/pages/showHtml/showTerms.vue")
    }
];
